<template>
    <div>
        <router-link class="come-back" to="/public_chamber_regions"><div></div>Вернуться в раздел “Общественные палаты регионов”</router-link>
        <div class="white-container region">
            <h1>{{resArray.name}}</h1>
            <tricolor-line class="tricolor_line"/>

            <ul class="region__info">
              <li class="region__info-item">
                <p class="region__info-title">Основание создания:</p>
                <p class="region__info-text">{{resArray.reason}}</p>
              </li>
              <li class="region__info-item" v-if="resArray.first_meeting_date!==null">
                <p class="region__info-title">Дата первого пленарного заседания текущего состава</p>
                <p class="region__info-text">{{resArray.first_meeting_date.split('T')[0]}}</p>
              </li>
              <li class="region__info-item">
                <p class="region__info-title">Срок полномочий:</p>
                <p class="region__info-text">{{resArray.term}}</p>
              </li>
              <li class="region__info-item">
                <p class="region__info-title">Количество членов Палаты в составе</p>
                <p class="region__info-text">{{resArray.memberCount}}</p>
              </li>
            </ul>

            <h2 class="region__info-text">Руководство</h2>

            <ul class="region__info">
              <li class="region__info-item">
                <p class="region__info-title">Председатель палаты:</p>
                <p class="region__info-link"> {{resArray.chairman}} </p>
              </li>
              <li class="region__info-item">
                <p class="region__info-title">Заместитель председателя:</p>
                <p class="region__info-link"> {{resArray.deputy_chairman}} </p>
              </li>
            </ul>

            <h2 class="region__info-text">Комиссии</h2>

            <ul class="region__info-item table">
              <li class="region__table-header">
                <p class="region__info-title" @click="sortBy('name')">Название комиссии
                  <svg :class="{'transform' : posName}" width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.69929 0.619996C5.28818 0.619996 5.6139 1.30287 5.2433 1.76052L3.33689 4.11474C3.05853 4.45849 2.53508 4.4611 2.2533 4.12014L0.30773 1.76592C-0.0694866 1.30947 0.255171 0.619996 0.847316 0.619996L4.69929 0.619996Z" fill="#5E686C"/>
                  </svg>
                </p>
                <p class="region__info-title" @click="sortBy('fio')">ФИО председателя
                  <svg :class="{'transform' : posFio}" width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.69929 0.619996C5.28818 0.619996 5.6139 1.30287 5.2433 1.76052L3.33689 4.11474C3.05853 4.45849 2.53508 4.4611 2.2533 4.12014L0.30773 1.76592C-0.0694866 1.30947 0.255171 0.619996 0.847316 0.619996L4.69929 0.619996Z" fill="#5E686C"/>
                  </svg>
                </p>
              </li>
               <li class="region__table-cell" v-for="(item, index) in filteredRows" :key="index">
                <p class="region__info-title mobile">Название комиссии</p>
                <p class="region__info-text" >{{ item.name }}</p>
                <p class="region__info-title mobile">ФИО председателя</p>
                <a class="region__info-link">{{ item.fio }}</a>
              </li>
            </ul>

        </div>

        <div class="white-container region__contacts">
          <h1>Контактная информация</h1>
          <tricolor-line class="tricolor_line"/>

          <ul class="region__contacts-list">
              <li class="region__info-item">
                <p class="region__info-title">Адрес</p>
                <p class="region__info-link">{{resArray.address}}</p>
              </li>
              <li class="region__info-item">
                <p class="region__info-title">Сайт</p>
                <p class="region__info-link">
                  <a :href="`http://${resArray.site}`">
                    {{resArray.site}}
                  </a>
                </p>
              </li>
              <!-- <li class="region__info-item">
                <p class="region__info-title">Аппарат</p>
                <p class="region__info-text">Есть</p>
              </li> -->
              <li class="region__info-item">
                <p class="region__info-title">Руководитель Аппарата</p>
                <p class="region__info-link">{{resArray.leader}}</p>
              </li>
          </ul>

          <ul class="region__contacts-list">
              <li class="region__info-item">
                <p class="region__info-title">Телефоны / факс</p>
                <p class="region__info-link">{{resArray.phone}}</p>
              </li>
              <li class="region__info-item">
                <p class="region__info-title">E-mail</p>
                <p class="region__info-link">{{resArray.email}}</p>
              </li>

          </ul>
        </div>
    </div>
</template>
<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'ChamberRegionsDetail',
  components: {
    TricolorLine
  },
  data () {
    return {
      resArray: [],
      nameCommission: [],
      isActiveItem: 0,

      sortKey: 'name',
      posName: true,
      posFio: true,
      sortPos: true,
      sortOrders: {}
    }
  },
  async mounted () {
    const id = this.$router.currentRoute.params.id
    let res = await fetch(`${this.$store.getters.getUrlApi}api/regional-public-сhamber-two/${id}`)
    res = await res.json()
    this.resArray = await res.data
    document.title = await res.data.name
  },
  methods: {
    sortBy: function (key) {
      key === 'name' ? this.posName = !this.posName : this.posFio = !this.posFio
      this.sortPos = !this.sortPos
      this.sortKey = key
      this.sortOrders[key] *= -1
    }
  },
  watch: {
    listData () {
      var arrName = []
      var i = 0
      var j = 0
      for (var item of this.resArray.commission_list) {
        if (!(i % 2)) {
          arrName.push({ name: item, fio: '' })
        } else {
          arrName[j].fio = item
          j++
        }
        i++
      }
      this.nameCommission = arrName
    }
  },
  computed: {
    listData () {
      return this.resArray
    },
    filteredRows () {
      const sortKey = this.sortKey
      const order = this.sortOrders[sortKey] || 1
      let rows = this.nameCommission
      if (sortKey) {
        rows = rows.sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]

          return this.sortPos ? (a === b ? 0 : a > b ? 1 : -1) * order : (a === b ? 0 : a < b ? 1 : -1) * order
        })
      }
      return rows
    }
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    width: 61.4rem;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h1{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}
h2 {
  margin-bottom: 1.375rem;
}
.mobile {
  display: none;
}
.region {
  margin-bottom: 2.8125rem;
}
.tricolor_line {
  margin-bottom: 2rem;
  width: 100%;
}
.region__info {
  margin-bottom: 2.5rem;

  &-item {
    &:not(:last-child) {
      margin-bottom: 1.375rem;
    }
  }
  &-title {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: rgba(23, 23, 23, 0.8);
    opacity: 0.8;
    margin-bottom: 0.4375rem;
  }
  &-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #1F3245;
  }
  &-link, &-link a {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;
  }
}

.region__table-header,
.region__table-cell {
  display: flex;
}
.table {
  li {
    border-bottom: 1px solid #D5D6D9;
    padding: 1.375rem 0;
  }
  .region__info-title {
    cursor: pointer;
  }
  .transform {
    transform: rotate(180deg);
  }
  .region__info-title:nth-child(1),
  .region__info-text {
    width: 32rem;
    margin-right: 2.4rem;
  }
  .region__info-title:nth-child(2),
  .region__info-link{
    width: 11.8rem;
  }

  .region__info-link{
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;
    &a{
      color: #246CB7;
    }
  }
}
.region__contacts {
  display: flex;
  flex-wrap: wrap;

  &-list {
    width: 50%;
    padding-right: 2rem;
  }
}
 .come-back {
    /*display: block;*/
    width: 84.37rem;
    margin: .875rem auto;
    font-size: .75rem;
    line-height: 1.38rem;
    display: flex;
    justify-content: flex-start;
    color: #246CB7;
    cursor: pointer;
  }

  .come-back div {
    position: relative;
    top: .625rem;
    width: .75rem;
    height: .0925rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    /*left:22em;*/
    display: block;
    border-radius: .820075px;
    margin-right: .4375rem;
  }
  .come-back div::after {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    top: -.23rem;
    left: .11rem;
    background-color: #337AB7;
    transform: rotate(-135deg);
    border-radius: .820075px;
  }
  .come-back div::before {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    /*top: 11px;*/
    left: .11rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(135deg);
    border-radius: .820075px;
  }

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  h2 {
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
  }
  .come-back {
    width: 90%;
  }

}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0 0 2rem 0;
        padding: 1.88rem 1rem;
    }
    .mobile {
      display: block;
    }
    .table {
      li:first-child{
        display: none;
      }
      li {
        display: flex;
        flex-direction: column;
      }
      .region__info-title:nth-child(1){
        width: 100%;
      }
      .region__info-text {
        width: 100%;
        margin: 0rem 0 1.375rem 0;
      }
      .region__info-title,
      .region__info-link{
        width: 100%;
      }

      .region__info-link{
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #246CB7;
      }
    }
    .region__contacts-list {
      width: 100%;
      margin-bottom: 1.375rem;
    }
    h2 {
      font-size: 1.125rem !important;
      line-height: 1.5rem !important;
    }
}
</style>
